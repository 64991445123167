import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/@core/auth/authentication.service';
import 'moment/locale/ar';
@Component({
  selector: 'app-one-column',
  templateUrl: './one-column.component.html',
  styleUrls: ['./one-column.component.scss'],
})
export class OneColumnComponent implements OnInit {
  isLogin: boolean = false;
  constructor(private Authentication: AuthenticationService) {}
  data: any;
  data2: any;
  ngOnInit(): void {
    this.getIsLogin();
    moment.locale('ar');
    this.data = moment().format('LL');

    // this.data2 = moment('1410/8/28', 'iYYYY/iM/iD').format('iYYYY/iM/iD'); // Parse a Hijri date.


   
  }

  logout() {
    this.Authentication.logout();
    this.ngOnInit();
  }

  getIsLogin() {
    this.Authentication.currentIsLogin.subscribe((res) => {
      this.isLogin = res;
    });
  }
}
