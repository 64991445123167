import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from './app-modules/home/home.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./app-modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'inquiries',
    loadChildren: () =>
      import('./app-modules/inquiries/inquiries.module').then(
        (m) => m.InquiriesModule
      ),
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./app-modules/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: 'travels',
    loadChildren: () =>
      import('./app-modules/my-travels/my-travels.module').then(
        (m) => m.MyTravelsModule
      ),
  },
  {
    path: 'main-data',
    loadChildren: () =>
      import('./app-modules/main-data/main-data.module').then(
        (m) => m.MainDataModule
      ),
  },
  {
    path: 'fatwa',
    loadChildren: () =>
      import('./app-modules/fatwas/fatwas.module').then((m) => m.FatwasModule),
  },
  {
    path: 'manasek',
    loadChildren: () =>
      import('./app-modules/manasek/manasek.module').then(
        (m) => m.ManasekModule
      ),
  },
  {
    path: 'lecture',
    loadChildren: () =>
      import('./app-modules/live-lectures/live-lectures.module').then(
        (m) => m.LiveLecturesModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./app-modules/news-articles/news-articles.module').then(
        (m) => m.NewsArticlesModule
      ),
  },
  {
    path: 'complaints',
    loadChildren: () =>
      import('./app-modules/complaints/complaints.module').then(
        (m) => m.ComplaintsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
